import React from "react";
import { Route } from "react-router-dom";
import SideNavbar from "../Components/SideNavbar/SideNavbar";
import "./Layouts.css";
export default function LayoutRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <CustomLayout>{Component && <Component {...props} />}</CustomLayout>
      )}
    />
  );
}
function CustomLayout({ children, Upload }) {
  return (
    <React.Fragment>
      <SideNavbar processorModule />
      <div id="mainContent">
        <div className="inner-wrapper">{children}</div>
      </div>
    </React.Fragment>
  );
}


// const ProcessorLayout = ({ Component }) => {
//   return <React.Fragment>
//     <SideNavbar processorModule />
//     <div id='mainContent'>
//       {/* <Header header='Settings > Queues' /> */}
//       <div className='inner-wrapper'>{Component}</div>
//     </div>
//   </React.Fragment>
// }

// export default ProcessorLayout
