import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RootRouter from './Routers/RootRouter';
import './App.css';
import { useHistory } from 'react-router-dom';

import { handleResetSearchTerm } from './Store/User/userAction';
// console.log = function () {};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.onpopstate = (e) => {
      console.log('back pressed');
      dispatch(handleResetSearchTerm());
    };
  });

  return (
    <div className='App'>
      <RootRouter />
    </div>
  );
}

export default App;
