import React from 'react';
import './Header.css';
import { useLocation, useHistory } from 'react-router-dom';
import back from '../../Images/back.svg';
import { resetPreProcessing } from '../../Store/PreProcessing/PreprocessingActions';
import { useDispatch } from 'react-redux';

const Header = (props) => {
  const location = useLocation();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { pathname } = location;
  console.log('pathname');
  const { header } = props;
  return (
    <div>
      <div className='headerClass d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          {pathname === '/digitizeview' || pathname === '/editdocument' ? (
            <img
              onClick={() => {
                if (pathname === '/editdocument') {
                  dispatch(resetPreProcessing());
                  sessionStorage.setItem('activeIndex', 1);
                  navigate.push({
                    pathname: '/processordashboard',
                  });
                } else {
                  sessionStorage.setItem('activeIndex', 0);
                  navigate.push({
                    pathname: '/processordashboard',
                  });
                }
              }}
              className='mr-2'
              style={{
                marginTop: '-3px',
                cursor: 'pointer',
              }}
              height={20}
              width={14}
              src={back}
              alt=''
            />
          ) : null}
          <div>{header}</div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Header;
