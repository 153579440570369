import React from 'react';
import { Button } from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

const ButtonPrimary = ({
  loader,
  onClick,
  loadingText,
  actualText,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled || loader}
      variant='contained'
      type='submit'
      style={{
        backgroundColor: disabled ? '#737373' : '#00716e',
        color: '#FFFFFF',
        textTransform: 'uppercase',
      }}
    >
      {loader ? (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        />
      ) : (
        ''
      )}
      {loader ? <div>&nbsp;{loadingText}...</div> : <> {actualText}</>}
    </Button>
  );
};

export default ButtonPrimary;
