import axios from 'axios';
import * as preProcessorType from './PreProcessingActionTypes';
import { toast } from 'react-toastify';
import { PATH, ADMIN_URL, PRODUCT_IP } from '../../Constants';
import {
  redirectToLogin,
  redirectToProcessorDashboard,
} from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');

export const preProcessing = () => async (dispatch) => {
  const caseId = { bId: parseInt(sessionStorage.getItem('caseId')) };
  const args = {
    method: 'post',
    url: `${PRODUCT_IP}${PATH.caseProcessing}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: caseId,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      dispatch({
        type: preProcessorType.PREPROCESSING_DATA,
        payload: [
          api.data.response.image_preprocessing_response,
          api.data.response.doc_type,
          api.data.response.jd_doctype,
          api.data.response.queue_type,
          api.data.response.batch_name,
        ],
      });
      console.log(api, 'hi there');
      //   dispatch()
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }

      if (error.response.status === 400) {
        toast.error(error?.response?.data?.message);
        redirectToProcessorDashboard();
      }
      return false;
    }
  }
};
// verify case data
export const verifyCaseData = () => async (dispatch) => {
  // dispatch({
  //   type: preProcessorType.PREPROCESSING_DATA,
  //   payload: [],
  // });
  const caseId = { cs_id: sessionStorage.getItem('caseId') };
  const args = {
    method: 'post',
    url: `${PRODUCT_IP}${PATH.verify_casedata}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: caseId,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.message);
      let result = JSON.parse(
        JSON.stringify(api?.data?.image_preprocessing_response)
      );
      Object.keys(result).forEach((key) => {
        result[key].img_dir_path = result[key].img_dir_path.replace(
          '/home/ubuntu/Credit/credit_app',
          ''
        );
        Object.assign({}, result, result);
      });
      dispatch({
        type: preProcessorType.PREPROCESSING_DATA,
        payload: [result, api.data.document_types, api.data.queue_type],
      });
      console.log(api, 'hi there');
      //   dispatch()
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const extractionProcessApi = (set) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_IP}${PATH.extractionProcess}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: set,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Update case data
export const updateCaseData = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${PRODUCT_IP}${PATH.update_casedata}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Splitting and Merging
export const splitMerge = (data) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.split_merge}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api.data.message);
      return true;
    } else {
      toast.error(api.data.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const resetPreProcessing = () => (dispatch) => {
  return dispatch({
    type: preProcessorType.PREPROCESSING_DATA,
    payload: [],
  });
};

export const addNewPreProcessing =
  (newJobId, docType) => (dispatch, getState) => {
    const { currentProcessed } = getState().preProcess;
    dispatch({
      type: preProcessorType.PREPROCESSING_DATA,
      payload: [
        ...currentProcessed,
        (currentProcessed[0][newJobId] = {
          filepath: '',
          img_dir_path: '',
          is_readable: true,
          images_count: 1,
        }),
        (currentProcessed[1][newJobId] = docType),
      ],
    });
  };

export const updatePreProcessing = (jobId, docType) => (dispatch, getState) => {
  const { currentProcessed } = getState().preProcess;
  dispatch({
    type: preProcessorType.PREPROCESSING_DATA,
    payload: [...currentProcessed, (currentProcessed[1][jobId] = docType)],
  });
};
